<template>
  <section v-if="!isServer && $banners.length">
    <div class="h-full md:rounded hidden justify-center sm:flex gap-x-3">
      <ImagenBanner
        :images="$banners[0].imagesUrls"
        :measures="measuresGrande"
        :size="'m'"
        :key="0"
        :alt="$banners[0].alt"
        :link="$banners[0].link ?? ''"
      ></ImagenBanner>
      <ImagenBanner
        v-if="$banners.length > 1"
        :images="$banners[1].imagesUrls"
        :measures="measuresGrande"
        :size="'m'"
        :key="1"
        :alt="$banners[1].alt"
        :link="$banners[1].link ?? ''"
      ></ImagenBanner>
    </div>
    <Flicking
      :options="options"
      :plugins="plugins"
      class="h-full md:rounded flex sm:hidden gap-x-3"
    >
      <ImagenBanner
        :images="$banners[0].imagesUrls"
        :measures="measuresGrande"
        :size="'m'"
        :key="0"
        :alt="$banners[0].alt"
        :link="$banners[0].link ?? ''"
      ></ImagenBanner>
      <ImagenBanner
        v-if="$banners.length > 1"
        :images="$banners[1].imagesUrls"
        :measures="measuresGrande"
        :size="'m'"
        :key="1"
        :alt="$banners[1].alt"
        :link="$banners[1].link ?? ''"
      ></ImagenBanner>
    </Flicking>
  </section>
</template>
<script setup lang="ts">
  import { ref, onMounted } from "@vue/runtime-core";
  import ImagenBanner from "@components/banners/ImagenBanner.vue";
  import { AutoPlay } from "@egjs/flicking-plugins";
  import type { Plugin as FlickingPlugin } from "@egjs/vue3-flicking";
  import Flicking from "@egjs/vue3-flicking";
  import { useStore } from "@nanostores/vue";
  import { bannersDobles } from "@stores/app";

  const $banners = useStore(bannersDobles);
  const isServer = ref(true);

  onMounted(() => {
    isServer.value = false;
  });

  const options = {
    align: "prev",
    circular: true,
    panelsPerView: 1,
    autoResize: true,
    preventDefaultOnDrag: true,
  };
  const plugins = ref<FlickingPlugin[]>([]);

  plugins.value = [
    new AutoPlay({ duration: 5000, direction: "NEXT", stopOnHover: true }),
  ];

  const measuresGrande = {
    l: {
      width: 1500,
      height: 500,
    },
    m: {
      width: 750,
      height: 250,
    },
    s: {
      width: 350,
      height: 125,
    },
  };
</script>
